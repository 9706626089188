import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
    root: {
        position: 'fixed',
        display: 'block',
        width: '100%',
        height: '100vh',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        backgroundColor: '#F5F5F6',
        cursor: 'pointer',
        zIndex: '3000',
        opacity:'.8'
    },
    loader: {
        position:'absolute',
        top:'50%',
        left:'50%',
        color:'white',
        zIndex: '3000',
        transform: 'translateX(-50%)'
    },
    message: {
        position:'absolute',
        height:'100px',
        top:'50%',
        left:'50%',
        zIndex: '4000',
        transform: 'translate(-50%, 50%)'

    }
}));

const Loader = (props) => {
    const classes = useStyles();
    const [progress, setProgress] = React.useState(0);

    React.useEffect(() => {
        function tick() {
            // reset when reaching 100%
            setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
        }

        const timer = setInterval(tick, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.message}>
                {props.message}
            </div>
            <div className={classes.loader}>
                <CircularProgress variant="determinate" value={progress} />
            </div>
        </div>
    )
}

export default Loader;