import { createContext } from 'react'

const SessionContext = createContext({
    /**
     * Although it is possible to remove the following I like to keep them here
     * because they help anyone importing SessionContext to understand what properties/functions
     * this particular context has available
     */
    /*
    isConnected:false,
    appVersion:'1.0',
    token: '',
    sessionData:{},
    */
    /**
     * {Object} userDetails i.e. {[name], [dateOfBirth], [email], [secretQuestion], [secretAnswer]}
     */
    setSessionContext: sessionContext=> {}
})

export default SessionContext