export const checkAllValidity = (form, formValidation) => {
    for(let [key, info] of Object.entries(formValidation)){
        if (!isNull(form[key])) {
            let result = checkValidity(ifNull(form[key].value, ''), info)
            if (result.valid == false){
                return false;
            }
        }
    }
    return true;
}
export const checkValidity = (value, validation) => {
    //console.log(validation)
    const checkFormat = (str, validation) => {
        let regex;
        switch(validation.type){
            case 'telephone':
                regex = RegExp(/^(0|\+[0-9]{2,3}|00[0-9]{2,3})[0-9]([-. ]?[0-9]{2}){4}$/);
                if (regex.test(str) == true && str != '') {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                } else if (str != '') {
                    return {valid:false, helperText:'Format téléphone invalide (00 00 00 00 00)'};
                } else {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                }
                break;
            case 'email':
                regex = RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/);
                if (regex.test(str) == true && str != '') {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                } else if (str != '') {
                    return {valid:false, helperText:'Format email invalide (test@test.com)'};
                } else {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                }
                break;
            case 'date':
                regex = RegExp(/(0[1-9]|1[0-9]|2[0-9]|3[01])\/(0[1-9]|1[012])\/[0-9]{4}$/);
                if (regex.test(str) == true && str != '') {
                    return {valid:true, helperText:''};
                } else if (str != '') {
                    return {valid:false, helperText:'Format date invalide (00/00/0000)'};
                } else {
                    return {valid:true, helperText:''};
                }
                break;
            case 'time':
                regex = RegExp(/[0-9]{2}(:|h|H|\.)[0-9]{2}$/);

                if (regex.test(str) == true && str != '') {
                    return {valid:true, helperText:''};
                } else if (str != '') {
                    return {valid:false, helperText:'Format heure invalide (00:00)'};
                } else {
                    return {valid:true, helperText:''};
                }
                break;
            case 'numeric':
                regex = RegExp(/[0-9]\.*$/);
                if (regex.test(str) == true && str != '') {
                    return {valid:true, helperText:''};
                } else if (str != '') {
                    return {valid:false, helperText:'Seul les caractères numériques sont acceptés'};
                } else {
                    return {valid:true, helperText:''};
                }
                break;
            case 'int':
                regex = RegExp(/[0-9]\.*$/);
                if (regex.test(str) == true && str != '') {
                    return {valid:true, helperText:''};
                } else if (str != '') {
                    return {valid:false, helperText:'Seul les caractères numériques sont acceptés'};
                } else {
                    return {valid:true, helperText:''};
                }
                break;
            case 'alphanumeric':
                regex = RegExp(/^[a-zA-Z ][a-zA-Z-_@ \n\.]{0,200}$/);
                if (regex.test(str) == true && str != '') {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                } else if (str != '') {

                    return {valid:false, helperText:'Seul les caractères alpha-numériques sont acceptés'};
                } else {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                }
                break;
            case 'alpha':
                regex = RegExp(/^[a-zA-Z][a-zA-Z-_@\.]{1,200}$/);
                if (regex.test(str) == true && str != '') {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                } else if (str != '') {
                    return {valid:false, helperText:'Seul les caractères alpha-numériques sont acceptés'};
                } else {
                    if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                        return {valid:true, helperText:''};
                    } else {
                        if (str.length < validation.length){
                            return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                        } else {
                            return {valid:true, helperText:''};
                        }
                    }
                }
                break;
            default:
                if (ifNull(validation.length, -1) == -1){ //Pas de contrainte de taille
                    return {valid:true, helperText:''};
                } else {
                    if (str.length < validation.length){
                        return {valid:false, helperText:validation.length + ' caractères minimum requis'};
                    } else {
                        return {valid:true, helperText:''};
                    }
                }
                break;
        }
    }
    if (validation.required == true){
        //je test si c'est vide
        if (value.trim() == '') {
            return {valid: false, helperText: 'Champ obligatoire'}
        } else { //je vérifie le type
            return checkFormat(value, validation);
        }
    } else {
        return checkFormat(value, validation);
    }
}
export const validateJSON = (str) => {
    try {
        const json = JSON.parse(str);
        if (Object.prototype.toString.call(json).slice(8,-1) !== 'Object') {
            return false;
        }
    } catch (e) {
        return false;
    }
    return true;
}
export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}
/**
 * Retourne un tableau de type Map contient toutes les variables posté en paramètre de l'url
 * @param props
 * @returns {Map<any, any>}
 */
export const getParamRequest = (props) => {
    //console.log(props)
    const param = new Map();

    const urlParams = new URLSearchParams(props.location.search);
    for (let data of urlParams) {
        param.set(data[0], data[1])
    }

    const urlPath = props.match.params;
    for(let key in urlPath) {
        param.set(key, urlPath[key]);
    }
    return param;
}

export const UUID=()=> {
    const s4=()=> Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4() + s4() + s4()}`;
}

export const isNull = (str)=> {

    if (typeof str === 'undefined'){
        //console.log('1 : undefined')
        return true;
    } else if (str === ''){
        //console.log('1 : \'\'')
        return true;
    } else if (str === 'null'){
        //console.log('1 : \'null\'')
        return true;
    } else if (str === null){
        //console.log('1 : null')
        return true;
    } else if (str === 0){
        //console.log('1 : 0')
        return true;
    } else if (typeof str === 'object'){
        if (Object.keys(str).length === 0 && str.constructor === Object) {
            return true;
        } else {
            return false;
        }
    } else {
        return false;
    }
}

export const ifNull = (str, value = '')=> {
    if (typeof str === 'undefined'){
        //console.log("undefined")
        return value;
    } else if (str === ''){
        //console.log("''")
        return value;
    } else if (str === 'null'){
        //console.log("'null'")
        return value;
    } else if (str === null){
        //console.log("null")
        return value;
    } else if (typeof str === 'object'){
        if (Object.keys(str).length === 0 && str.constructor === Object) {
            return value;
        } else {
            return str;
        }
    } else {
        return str;
    }
}

export const ifString = (str)=> {
    if (typeof str !== 'string'){
        return str + '';
    } else {
        return str;
    }
}


/*\
|*|
|*|  utilitairezs de manipulations de chaînes base 64 / binaires / UTF-8
|*|
|*|  https://developer.mozilla.org/fr/docs/Décoder_encoder_en_base64
|*|
\*/

/* Décoder un tableau d'octets depuis une chaîne en base64 */

function b64ToUint6 (nChr) {

    return nChr > 64 && nChr < 91 ?
        nChr - 65
        : nChr > 96 && nChr < 123 ?
            nChr - 71
            : nChr > 47 && nChr < 58 ?
                nChr + 4
                : nChr === 43 ?
                    62
                    : nChr === 47 ?
                        63
                        :
                        0;

}

function base64DecToArr (sBase64, nBlocksSize) {

    var
        sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, ""), nInLen = sB64Enc.length,
        nOutLen = nBlocksSize ? Math.ceil((nInLen * 3 + 1 >> 2) / nBlocksSize) * nBlocksSize : nInLen * 3 + 1 >> 2, taBytes = new Uint8Array(nOutLen);

    for (var nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
        nMod4 = nInIdx & 3;
        nUint24 |= b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << 18 - 6 * nMod4;
        if (nMod4 === 3 || nInLen - nInIdx === 1) {
            for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
                taBytes[nOutIdx] = nUint24 >>> (16 >>> nMod3 & 24) & 255;
            }
            nUint24 = 0;

        }
    }

    return taBytes;
}

/* encodage d'un tableau en une chaîne en base64 */

function uint6ToB64 (nUint6) {

    return nUint6 < 26 ?
        nUint6 + 65
        : nUint6 < 52 ?
            nUint6 + 71
            : nUint6 < 62 ?
                nUint6 - 4
                : nUint6 === 62 ?
                    43
                    : nUint6 === 63 ?
                        47
                        :
                        65;

}

export const base64EncArr = (aBytes) => {

    var nMod3 = 2, sB64Enc = "";

    for (var nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
        nMod3 = nIdx % 3;
        if (nIdx > 0 && (nIdx * 4 / 3) % 76 === 0) { sB64Enc += "\r\n"; }
        nUint24 |= aBytes[nIdx] << (16 >>> nMod3 & 24);
        if (nMod3 === 2 || aBytes.length - nIdx === 1) {
            sB64Enc += String.fromCharCode(uint6ToB64(nUint24 >>> 18 & 63), uint6ToB64(nUint24 >>> 12 & 63), uint6ToB64(nUint24 >>> 6 & 63), uint6ToB64(nUint24 & 63));
            nUint24 = 0;
        }
    }

    return sB64Enc.substr(0, sB64Enc.length - 2 + nMod3) + (nMod3 === 2 ? '' : nMod3 === 1 ? '=' : '==');

}

/* Tableau UTF-8 en DOMString et vice versa */

function UTF8ArrToStr (aBytes) {

    var sView = "";

    for (var nPart, nLen = aBytes.length, nIdx = 0; nIdx < nLen; nIdx++) {
        nPart = aBytes[nIdx];
        sView += String.fromCharCode(
            nPart > 251 && nPart < 254 && nIdx + 5 < nLen ? /* six bytes */
                /* (nPart - 252 << 32) n'est pas possible pour ECMAScript donc, on utilise un contournement... : */
                (nPart - 252) * 1073741824 + (aBytes[++nIdx] - 128 << 24) + (aBytes[++nIdx] - 128 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
                : nPart > 247 && nPart < 252 && nIdx + 4 < nLen ? /* five bytes */
                (nPart - 248 << 24) + (aBytes[++nIdx] - 128 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
                : nPart > 239 && nPart < 248 && nIdx + 3 < nLen ? /* four bytes */
                    (nPart - 240 << 18) + (aBytes[++nIdx] - 128 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
                    : nPart > 223 && nPart < 240 && nIdx + 2 < nLen ? /* three bytes */
                        (nPart - 224 << 12) + (aBytes[++nIdx] - 128 << 6) + aBytes[++nIdx] - 128
                        : nPart > 191 && nPart < 224 && nIdx + 1 < nLen ? /* two bytes */
                            (nPart - 192 << 6) + aBytes[++nIdx] - 128
                            : /* nPart < 127 ? */ /* one byte */
                            nPart
        );
    }

    return sView;

}

export const strToUTF8Arr =  (sDOMStr) => {

    var aBytes, nChr, nStrLen = sDOMStr.length, nArrLen = 0;

    /* mapping... */

    for (var nMapIdx = 0; nMapIdx < nStrLen; nMapIdx++) {
        nChr = sDOMStr.charCodeAt(nMapIdx);
        nArrLen += nChr < 0x80 ? 1 : nChr < 0x800 ? 2 : nChr < 0x10000 ? 3 : nChr < 0x200000 ? 4 : nChr < 0x4000000 ? 5 : 6;
    }

    aBytes = new Uint8Array(nArrLen);

    /* transcription... */

    for (var nIdx = 0, nChrIdx = 0; nIdx < nArrLen; nChrIdx++) {
        nChr = sDOMStr.charCodeAt(nChrIdx);
        if (nChr < 128) {
            /* one byte */
            aBytes[nIdx++] = nChr;
        } else if (nChr < 0x800) {
            /* two bytes */
            aBytes[nIdx++] = 192 + (nChr >>> 6);
            aBytes[nIdx++] = 128 + (nChr & 63);
        } else if (nChr < 0x10000) {
            /* three bytes */
            aBytes[nIdx++] = 224 + (nChr >>> 12);
            aBytes[nIdx++] = 128 + (nChr >>> 6 & 63);
            aBytes[nIdx++] = 128 + (nChr & 63);
        } else if (nChr < 0x200000) {
            /* four bytes */
            aBytes[nIdx++] = 240 + (nChr >>> 18);
            aBytes[nIdx++] = 128 + (nChr >>> 12 & 63);
            aBytes[nIdx++] = 128 + (nChr >>> 6 & 63);
            aBytes[nIdx++] = 128 + (nChr & 63);
        } else if (nChr < 0x4000000) {
            /* five bytes */
            aBytes[nIdx++] = 248 + (nChr >>> 24);
            aBytes[nIdx++] = 128 + (nChr >>> 18 & 63);
            aBytes[nIdx++] = 128 + (nChr >>> 12 & 63);
            aBytes[nIdx++] = 128 + (nChr >>> 6 & 63);
            aBytes[nIdx++] = 128 + (nChr & 63);
        } else /* if (nChr <= 0x7fffffff) */ {
            /* six bytes */
            aBytes[nIdx++] = 252 + /* (nChr >>> 32) is not possible in ECMAScript! So...: */ (nChr / 1073741824);
            aBytes[nIdx++] = 128 + (nChr >>> 24 & 63);
            aBytes[nIdx++] = 128 + (nChr >>> 18 & 63);
            aBytes[nIdx++] = 128 + (nChr >>> 12 & 63);
            aBytes[nIdx++] = 128 + (nChr >>> 6 & 63);
            aBytes[nIdx++] = 128 + (nChr & 63);
        }
    }

    return aBytes;

}