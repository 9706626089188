import React, {useContext} from 'react';
import { Route } from "react-router-dom";
import SessionContext from "./context/SessionContext";
import Login from './page/Login';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isConnected } = useContext(SessionContext);

    return (
        <Route {...rest} render={(props) => (
            (isConnected) === true
                ? <Component {...rest} {...props}  />
                : <Login {...rest} {...props} />
        )}
        />
    )
}
export default PrivateRoute;
