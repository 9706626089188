import Dexie from 'dexie';
// import 'dexie-observable';
// import 'dexie-syncable';

const db = new Dexie('Levrard');

const version = (localStorage.getItem('verno')*1 === 0 || localStorage.getItem('verno') === 'null')?1:localStorage.getItem('verno');

db.version(version).stores({
    utilisateur:'utilisateur_uid,utilisateur_id,utilisateur_login,utilisateur_pass,utilisateur_droit,utilisateur_nom,utilisateur_prenom,utilisateur_admin,utilisateur_fonction,utilisateur_num_secu,utilisateur_date_naissance,utilisateur_date_entree,utilisateur_date_sortie,utilisateur_email,utilisateur_pass_email,utilisateur_banque,utilisateur_adresse,utilisateur_cp,utilisateur_ville,utilisateur_tel_perso,utilisateur_tel_pro,utilisateur_tel_perso_annuaire,utilisateur_commentaire,utilisateur_planning,utilisateur_initiale,utilisateur_droit_valid_techn,utilisateur_droit_valid_admin,utilisateur_temps_hebdomadaire,utilisateur_reserve_heure,duree_travail_id,utilisateur_astreinte_montant,utilisateur_astreinte_montant_jf,utilisateur_use_reserve_heure,token',
    technicien:'technicien_uid,technicien_id,utilisateur_login,utilisateur_pass,utilisateur_droit,utilisateur_nom,utilisateur_prenom,utilisateur_admin,utilisateur_fonction,utilisateur_num_secu,utilisateur_date_naissance,utilisateur_date_entree,utilisateur_date_sortie,utilisateur_email,utilisateur_pass_email,utilisateur_banque,utilisateur_adresse,utilisateur_cp,utilisateur_ville,utilisateur_tel_perso,utilisateur_tel_pro,utilisateur_tel_perso_annuaire,utilisateur_commentaire,utilisateur_planning,utilisateur_initiale,utilisateur_droit_valid_techn,utilisateur_droit_valid_admin,utilisateur_temps_hebdomadaire,utilisateur_reserve_heure,duree_travail_id,utilisateur_astreinte_montant,utilisateur_astreinte_montant_jf,utilisateur_use_reserve_heure',
    article:'article_uid,article_id,article_categorie_id,article_libelle,article_code,dechet_id,article_description,article_pu_ht,article_actif,unite_id,article_prime_actif,article_prime_montant',
    article_categorie:'article_categorie_uid,article_categorie_id,article_famille_id,article_categorie_libelle,article_categorie_code,article_categorie_actif',
    article_compose:'compose_uid,compose_id,principal_article_id,compose_article_id,is_option,compose_ordre,is_inclus,prime_montant',
    article_famille:'article_famille_uid,article_famille_id,article_famille_libelle,article_famille_code,article_famille_actif,article_famille_color',
    banque:'banque_uid,banque_id,banque_libelle,banque_compte,banque_iban,banque_swift,banque_defaut,banque_actif,banque_journal,banque_code_compta,banque_dom',
    bsdd_emetteur:'emetteur_uid,emetteur_id,emetteur_libelle',
    civilite:'civilite_uid,civilite_id,civilite_libelle,civilite_actif',
    conditionnement:'cond_uid,cond_id,cond_libelle,cond_actif',
    contact:'contact_uid,contact_id,tiers_id,contact_nom,contact_prenom,civilite_id,contact_actif,contact_fonction,contact_principal',
    contact_coord:'coord_uid,coord_id,contact_coord_rowid,contact_id,coord_type_id,coord_valeur,coord_libelle',
    coord_type:'coord_type_uid,coord_type_id,coord_type_libelle,coord_type_actif',
    cuve_fioul:'cuve_fioul_uid,cuve_fioul_id,tiers_id,cuve_fioul_libelle,cuve_fioul_capacite,cuve_fioul_type,cuve_fioul_type_fond,cuve_fioul_acces,cuve_fioul_emplacement,cuve_fioul_emplacement_autre,cuve_fioul_retention,cuve_fioul_acces_retention,cuve_fioul_distance_plafond,cuve_fioul_stationnement,cuve_fioul_stationnement_type,cuve_fioul_distance,cuve_fioul_commentaire',
    dechet:'dechet_uid,dechet_id,dechet_code,dechet_rubrique,dechet_libelle,dechet_code_un,dechet_code_etiquette,dechet_consistance_id,dechet_code_tunnel,dechet_code_danger,dechet_dangereux,dechet_actif,dechet_abrev,dechet_mention_adr',
    dechet_consistance:'dechet_consistance_uid,dechet_consistance_id,dechet_consistance_libelle,dechet_consistance_actif',
    document:'doc_uid,doc_id,doc_taille,doc_nom,doc_path,doc_type,objet_table,objet_id,objet_uid,doc_date,utilisateur_id,doc_type_id,doc_data',
    document_type:'doc_type_uid,doc_type_id,doc_type_libelle,doc_type_actif',
    duree:'duree_uid,duree_id,duree_libelle,duree_minute,duree_actif,duree_unite',
    heure_travail:'heure_travail_uid,heure_travail_id,heure_travail_rowid,utilisateur_id,heure_travail_date,debut_journee,debut_dejeuner,fin_dejeuner,fin_journee,temps_atelier,temps_entretien,heure_travail_commentaire,heure_travail_valide,utilisateur_validation,heure_travail_panier_repas',
    heure_travail_imprevue:'heure_travail_imprevue_uid,heure_travail_imprevue_id,heure_travail_imprevue_rowid,int_id,utilisateur_id,heure_travail_imprevue_date,heure_travail_imprevue_debut,heure_travail_imprevue_fin,heure_travail_imprevue_commentaire,heure_travail_imprevue_commentaire_utili,heure_travail_imprevue_valide,utilisateur_validation',
    intervention:'int_uid,int_id,client_id,payeur_id,contrat_id,int_type_id,int_description,int_libelle,int_date_origine,int_etat_id,int_termine,parc_id,chauffeur_id,operateur_id,travaux_adresse_id,facturation_adresse_id,int_interne,int_blob_client,int_blob_payeur,int_date_prev,duree_prev_id,int_date_rea,duree_rea_id,int_times_debut,int_times_fin,int_rapport,int_exploit,int_administratif,int_ref_client,int_reglement,int_reglement_obtenu,int_reglement_commentaire,int_facturable,int_facturable_commentaire,int_fulltext,int_planif_by,int_bdc_doc_id,int_parent_id,int_signataire,fact_id,devis_id,int_jour_fixe,int_modele_id,reglement_id,encaissement_ref,encaissement_commentaire,utilisateur_id,int_local_id,int_local_autre,int_titre_id,int_titre_autre,int_attent_client,int_controle_id,int_controle_date,int_donneur_ordre,int_validation_technique,int_validation_technique_id,int_validation_technique_date,activite_id,int_num_passage,int_passage_type_id,intervention_traitement_id,int_signature_rapport,int_signature_proposition,int_signataire_proposition,int_heure_imperative,motif_pas_fait_id,motif_pas_fait,email_ticket,telephone_ticket,int_attestation_tva,int_attestation_tva_signe',
    intervention_contacts:'int_contact_uid,int_id,contact_id,intervention_contacts_a_rappeler',
    intervention_dechet:'int_dechet_uid,int_dechet_id,int_detail_id,int_id,int_dechet_date,emetteur_id,tiers_provisoire_id,tiers_transporteur_id,tiers_destination_id,tiers_ulterieure_id,cond_id,qt_recycle',
    intervention_detail:'int_detail_uid,int_detail_id,intervention_detail_rowid,int_id,article_id,article_code,unite_id,int_detail_quantite,int_detail_pu_ht,int_detail_libelle,int_detail_parent_id,int_detail_parent_uid,int_detail_parent_rowid,int_detail_ordre,tva_taux,tva_id,int_detail_astreinte,int_detail_total_ht,int_detail_qt_recycle',
    intervention_etat:'int_etat_uid,int_etat_id,int_etat_libelle,int_etat_couleur,int_etat_actif,int_etat_ordre',
    intervention_local:'int_local_uid,int_local_id,int_local_libelle,int_local_actif',
    intervention_materiels:'int_materiel_uid,materiel_id,int_id',
    intervention_signalement:'intervention_signalement_uid,intervention_signalement_id,int_id,intervention_signalement_logitude,intervention_signalement_latitude,intervention_signalement_commentaire,document_id',
    intervention_signature:'int_sig_uid,int_sig_id,int_id,int_rowid,int_sig,int_sig_devis',
    intervention_type:'int_type_uid,int_type_id,int_type_libelle,int_type_code,int_interne,int_type_actif',
    intervention_commentaire:'intervention_commentaire_uid,intervention_commentaire_id,int_id,intervention_commentaire_passe,intervention_commentaire_present,intervention_commentaire_futur',
    materiel:'materiel_uid,materiel_id,materiel_libelle,materiel_actif,materiel_categorie_id',
    materiel_categorie:'materiel_categorie_uid,materiel_categorie_id,materiel_categorie_libelle,materiel_categorie_actif',
    parc:'parc_uid,parc_id,parc_rowid,parc_type_id,parc_categorie_id,parc_libelle,parc_commentaire,parc_marque,parc_code,parc_immat,parc_km,parc_date_entree,parc_date_sortie,parc_date_mec,conducteur_id,operateur_id,parc_planning,parc_couleur,parc_heure,parc_date_maj',
    parc_categorie:'parc_categorie_uid,parc_categorie_id,parc_categorie_libelle,parc_categorie_actif',
    parc_entretien:'parc_entretien_uid,parc_entretien_id,parc_entretien_libelle,parc_entretien_commentaire,parc_entretien_montant,parc_entretien_km,parc_entretien_date,parc_id,alerte_type_id,parc_entretien_actif,parc_entretien_commentaire_prevision,parc_entretien_heure_entretien,parc_entretien_heure_equip,parc_entretien_heure_moteur',
    parc_type:'parc_type_uid,parc_type_id,parc_type_libelle,parc_type_actif',
    preconisation:'preconisation_uid,preconisation_id,preconisation_libelle,preconisation_prime,preconisation_actif',
    preconisation_chauffeur:'preconisation_chauffeur_uid,preconisation_chauffeur_id,int_id,utilisateur_id,preconisation_id,devis_id,preconisation_chauffeur_actif,preconisation_chauffeur_date',
    preconisation_chauffeur_detail:'preconisation_chauffeur_detail_uid,preconisation_chauffeur_detail_id,preconisation_chauffeur_id,preconisation_question_id,preconisation_chauffeur_detail_reponse',
    preconisation_question:'preconisation_question_uid,preconisation_question_id,preconisation_id,preconisation_question_type_id,preconisation_question_libelle,preconisation_question_actif,preconisation_question_ordre',
    preconisation_question_type:'preconisation_question_type_uid,preconisation_question_type_id,preconisation_question_type_libelle,preconisation_question_type_actif,preconisation_question_type_html',
    tiers:'tiers_uid,tiers_id,civilite_id,tiers_raison_sociale,tiers_libelle,tiers_code_interne,tiers_code_compta,tiers_type_id,reglement_id,reglement_ech_id,reglement_ech_jour,tva_id,tiers_tva_intra,tiers_commentaire,tiers_commentaire_exploit,tiers_actif,tiers_payeur_id,tiers_tel_fixe,tiers_tel_portable,tiers_email,tiers_categorie_id,tiers_bloque,tiers_bdc,tiers_siret,tiers_code_recipisse,tiers_date_recipisse,tiers_signataire,tiers_levrard,tiers_code_dr,utilisateur_id,tiers_devis,tiers_chorus,tiers_rapport_urgent,tiers_annuaire,tiers_relance_facture,tiers_relance_facture_email,adresse_categorie_id',
    tiers_adresse:'adresse_uid,adresse_id,tiers_id,adresse_libelle,adresse_rue,adresse_batiment,adresse_complement,adresse_code_postal,adresse_commune,adresse_pays,adresse_latitude,adresse_longitude,adresse_principale,adresse_facturation,adresse_actif,adresse_siret,adresse_code_service,adresse_type',
    tiers_cap:'tiers_cap_uid,tiers_id,dechet_id,code_cap',
    tiers_categorie:'tiers_categorie_uid,tiers_categorie_id,tiers_categorie_libelle,tiers_categorie_reglement,tiers_categorie_actif,tiers_categorie_code,tiers_categorie_color',
    tiers_tarif:'tiers_tarif_uid,tiers_id,article_id,tiers_tarif_pu_ht',
    tiers_type:'tiers_type_uid,tiers_type_id,tiers_type_libelle,tiers_type_actif',
    tva:'tva_uid,tva_id,tva_libelle',
    tva_taux:'taux_uid,taux_id,taux_valeur,tva_compte,tva_id,description,date_debut,date_fin',
    unite:'unite_uid,unite_id,unite_libelle,unite_actif',
    reglement:'reglement_uid,reglement_id,reglement_libelle,reglement_code,reglement_actif',
    contrat:'contrat_uid,contrat_id,client_id,payeur_id,suivi_id,suivi_email,travaux_adresse_id,facturation_adresse_id,contrat_type_id,int_type_id,contrat_libelle,contrat_commentaire,contrat_date_debut,contrat_date_fin,contrat_facturation_mois,contrat_facturation_jour,contrat_actif,location_periodicite,location_description,location_administratif,location_date_origine,utilisateur_id,devis_id,activite_id,logement_groupe,logement_groupe_libelle,risque_type_id',
    contrat_detail:'contrat_detail_uid,contrat_detail_id,contrat_id,article_id,article_code,unite_id,contrat_detail_quantite,contrat_detail_pu_ht,contrat_detail_libelle,contrat_detail_ordre,tva_taux,tva_id,contrat_detail_astreinte,contrat_detail_total_ht,contrat_detail_parent_id',
    intervention_logement:'int_logement_uid,int_logement_id,int_id,logement_id,int_logement_type_id,int_logement_commentaire,int_logement_curatif,infestation_niveau,int_punaise,int_traitement_id,fiche_explicative,int_logement_signature,email_ticket,telephone_ticket,encaissement_id,reglement_id,encaissement_ref,encaissement_date,encaissement_montant',
    intervention_logement_type:'int_logement_type_uid,int_logement_type_id,int_logement_type_libelle,int_logement_type_couleur,int_logement_type_actif',
    intervention_traitement:'intervention_traitement_uid,intervention_traitement_id,intervention_traitement_libelle,intervention_traitement_actif,intervention_traitement_color',
    tiers_logement:'logement_uid,logement_id,adresse_id,contrat_id,logement_adresse,logement_batiment,logement_entree,logement_numero,logement_numero_saisie,logement_libelle,logement_tel,logement_actif,import_reference',
    fiche_explicative:'fiche_explicative_uid,fiche_explicative_id,fiche_explicative_libelle,fiche_explicative_actif',
    feedback_type:'feedback_type_uid,feedback_type_id,feedback_type_libelle,feedback_type_actif',
    feedback:'feedback_uid,feedback_id,feedback_date,utilisateur_id,feedback_type_id,feedback_note',
    questionnaire:'questionnaire_uid,questionnaire_id,questionnaire_libelle,questionnaire_utilisation,int_type,questionnaire_description,questionnaire_actif',
    questionnaire_rubrique:'questionnaire_rubrique_uid,questionnaire_rubrique_id,questionnaire_id,questionnaire_rubrique_libelle,questionnaire_rubrique_ordre',
    question:'question_uid,question_id,questionnaire_rubrique_id,question_type_id,question_texte,reponse_type,reponse_numerique,reponse_obligatoire,question_ordre,non_visible_client,non_visible_operateur',
    reponse:'reponse_uid,reponse_id,question_id,reponse_type,reponse_valeur,reponse_affichage,reponse_ordre',
    questionnaire_resultat:'questionnaire_resultat_uid,questionnaire_resultat_id,questionnaire_id,questionnaire_libelle,questionnaire_rubrique_id,questionnaire_rubrique_libelle,question_id,question_texte,reponse_type,commentaire,date,objet_table,objet_id,objet_uid',
    intervention_autre_type:'intervention_autre_type_uid,intervention_autre_type_id,int_id,int_type_id',
    absence_type:'absence_type_uid,absence_type_id,absence_type_libelle,absence_type_abrev,absence_type_couleur,absence_type_actif,absence_type_use_reserve,absence_type_modulation,absence_type_modulation_deduction,absence_type_ferie_inclus,absence_type_affichage_mobile',
    absence:'absence_uid,absence_id,absence_type_id,utilisateur_id,absence_date_debut,absence_date_fin,absence_commentaire,absence_nb_jours,absence_demi,absence_compte_heure,absence_compte_heure_total,absence_statut_id',
    adresse_categorie:'adresse_categorie_uid,adresse_categorie_id,adresse_categorie_libelle,adresse_categorie_ordre,adresse_categorie_actif',
    intervention_logement_vente:'vente_uid,vente_id,int_id,logement_id,article_id,article_code,unite_id,quantite,pu_ht,libelle,parent_id,parent_uid,ordre,tva_taux,tva_id,total_ht',
    updated_data:'data_uid,data_id,utilisateur_id,data_action,objet_table,objet_uid,objet_id,objet_key,data_date,data,processed'
});


db.open()/*.then(function (db) {

    db.tables.forEach(function (table) {
        console.log("Found table: " + table.name);
        //console.log("Table Schema: " + JSON.stringify(table.schema, null, 4));
    });

})*/;

export default db;