import React, {Suspense, lazy, useContext} from 'react';
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';


import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import SessionProvider from './context/SessionProvider'
import PrivateRoute from "./PrivateRoute";
import SessionContext from "./context/SessionContext";
import ScrollToTop from './component/ScrollToTop'
import Loader from './component/Loader'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import frLocale from "date-fns/locale/fr";

// pick a date util library
import DateFnsUtils from '@date-io/date-fns';

const Home = lazy(() => import('./page/Home'));

const useStyles = makeStyles({
    body: {
        width: '100%',
        height:'100vh'
    }
});



const theme = createMuiTheme({
    palette: {
        primary: {
            light: '#494751',
            main: '#212029',
            dark: '#000000',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff6358',
            main: '#ec252e',
            dark: '#b10006',
            contrastText: '#fff',
        },
    },
    overrides: {
        // Style sheet name ⚛️
        MuiBottomNavigation : {
            root: {
                backgroundColor: 'black',
                color:'white',
            }
        },
        // Style sheet name ⚛️
        MuiBottomNavigationAction : {
            root: {
                fontSize:'10px',
                color:'white',
                "&$selected": {
                    /*backgroundColor: "#ec252e",*/
                    color: "#ec252e",
                    "&:hover": {
                        backgroundColor: "#494751"
                    }
                }
            }
        },

    },
});

function App() {

    const {isConnected, token, appVersion, sessionData} = useContext(SessionContext)


    return (
          <React.Fragment>
              <ThemeProvider theme={theme}>
                  <SessionProvider>
                          <CssBaseline />
                          <MuiPickersUtilsProvider  locale={frLocale} utils={DateFnsUtils}>
                              <Container maxWidth={"lg"} style={{"padding":0}}>
                                  <Grid container direction="column" justify="flex-start" alignItems="stretch" style={{position:'relative'}}>
                                      <Suspense fallback={<Loader/>}>
                                          <ScrollToTop>
                                              <Switch>
                                                  <PrivateRoute path='/' component={Home}/>
                                              </Switch>
                                          </ScrollToTop>
                                      </Suspense>
                                  </Grid>
                              </Container>
                          </MuiPickersUtilsProvider>
                  </SessionProvider>
              </ThemeProvider>
          </React.Fragment>
    );
}

export default App;
