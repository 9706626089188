import React, { useState } from 'react'
import SessionContext from './SessionContext'

const SessionProvider = ({ children }) => {
    /**
     * Sessoin state / controls
     */
    const setSessionContext = ({ isConnected, token, sessionData }) => {
        updateSessionContext(prevState => {
            const newState = {...prevState, isConnected, token, sessionData};
            //localStorage['session'] = JSON.stringify(newState);
            // sessionStorage['token'] = JSON.stringify(token);
            localStorage['token'] = token;
            return newState;
        })
    }

    const sessionState = {

        isConnected: false,
        token: '',
        appVersion: '6.5',
        sessionData:{},
        setSessionContext
    }

    const [sessionContext, updateSessionContext] = useState(sessionState);

    /*
    useEffect(() => {
        if (validateJSON(localStorage['session'])){
            setSessionContext(JSON.parse(localStorage['session']));
        } else {
            setSessionContext({});
        }
    },[]);
    */
    return (
        <SessionContext.Provider value={sessionContext}>
            {children}
        </SessionContext.Provider>
    )
}

export default SessionProvider