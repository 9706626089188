import React, {useState, useContext, useEffect} from 'react';
import { Redirect, useHistory } from "react-router-dom";
import SessionContext from '../context/SessionContext';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {ifNull, isNull, sleep, UUID} from '../Helper';

import Dexie from 'dexie';
import db from '../database/Db';
import Loader from "../component/Loader";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const axios = require('axios');
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.baseURL = '/';

const bcrypt = require('bcryptjs');

const useStyles = makeStyles({
    body: {
        width: '100%',
        height:'100vh'
    }
});

const Login = (props) => {

    const {isConnected, token, appVersion, sessionData, setSessionContext} = useContext(SessionContext);
    const classes = useStyles();
    const formLogin = React.useRef(null);
    const [value, setValue] = React.useState(0);
    const [validated, setValidated] = React.useState("false");
    const [formData, setFormData] = React.useState({
        login:'',
        password:''
    });
    const [formValidation, setValidation] = React.useState({
        login:'required',
        password:'required'
    });
    const [formErrorHelper, setFormErrorHelper] = React.useState({
        login:'',
        password:''
    });
    const [formError, setError] = React.useState({
        login:false,
        password:false
    });

    const [Loading, setLoading] = useState({
        show:false,
        message:''
    });

    let history = useHistory();


    useEffect(() => {
        if (isNull(localStorage['token']) == false){
            db.transaction('rw', db.utilisateur, async ()=> {
                let utilisateur = await db.utilisateur.get({token: localStorage['token']});
                if (!isNull(utilisateur)) {
                    setSessionContext({
                        isConnected: true,
                        token: utilisateur.token,
                        sessionData: {
                            user: {
                                utilisateur_id: utilisateur.utilisateur_id,
                                utilisateur_nom: utilisateur.utilisateur_nom,
                                utilisateur_prenom: utilisateur.utilisateur_prenom,
                                utilisateur_login: utilisateur.utilisateur_login
                            }
                        }
                    });
                }
            });
        }
    },[]);

    const confirmDeleteDatabase = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Réinitialisation</h1>
                        <p>Vous confirmez la suppression des données ?</p>
                        <Button onClick={onClose} variant="contained">Non</Button>
                        &nbsp;&nbsp;
                        <Button variant="contained" color={"primary"} onClick={() => {deleteDatabase(); onClose();}}>
                            Oui, Supprimer
                        </Button>
                    </div>
                );
            }
        })
    }

    const deleteDatabase = () => {

        db.delete().then(() => {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className='custom-ui'>
                            <h1>Réinitialisation</h1>
                            <p>La suppression a été effectuée</p>
                            <Button variant="contained" color={"primary"} onClick={() => {document.location.href='/'; onClose();}}>
                                OK
                            </Button>
                        </div>
                    );
                }
            })
            console.log("Database successfully deleted");
        }).catch((err) => {
            console.error("Could not delete database");
        }).finally(() => {
            // Do what should be done next...
        });
    }

    const handleChangeLogin = (e) => {
        setFormData({...formData, login:e.target.value});
        let formErrorHelperTmp = {...formErrorHelper};
        let formErrorTmp = {...formError};
        if (!formLogin.current.login.checkValidity()){
            formErrorHelperTmp[e.target.id] = 'Champ obligatoire';
            formErrorTmp[e.target.id] = true;
        } else {
            formErrorHelperTmp[e.target.id] = '';
            formErrorTmp[e.target.id] = false;
        }
        setFormErrorHelper(formErrorHelperTmp);
        setError(formErrorTmp);
    }

    const handleChangePassword = (e) => {
        setFormData({...formData, password:e.target.value})
        let formErrorHelperTmp = {...formErrorHelper};
        let formErrorTmp = {...formError};
        if (!formLogin.current.password.checkValidity()){
            formErrorHelperTmp[e.target.id] = 'Champ obligatoire';
            formErrorTmp[e.target.id] = true;
        } else {
            formErrorHelperTmp[e.target.id] = '';
            formErrorTmp[e.target.id] = false;
        }
        setFormErrorHelper(formErrorHelperTmp);
        setError(formErrorTmp);
    }

    const initDb = async (login, pass) => {
        setLoading({show:true, message:'Téléchargement des données'});
        let param = new FormData()
        param.append("username", formData.login)
        param.append("password", formData.password)
        await axios.post('/webservice/' + appVersion + '/token.php', param).then(function (response) {
           let data = response.data
            console.log(response.data)
            if (data.user !== undefined) {
                const execute = async () =>{
                    const currentSchema = await db.tables.reduce((result, {name, schema}) => {
                        result[name] = [
                            schema.primKey.src,
                            ...schema.indexes.map(idx => idx.src)
                        ].join(',');
                        return result;
                    }, {});
                    let newDb = await changeSchema(db, currentSchema);
                    setSessionContext({
                        isConnected: false,
                        token: data.token,
                        sessionData : {
                            user: data.user
                        }
                    });

                    let salt = bcrypt.genSaltSync(10);
                    let passwordHash = bcrypt.hashSync(formData.password, salt);

                    newDb.utilisateur.put({
                        token: data.token,
                        utilisateur_uid: data.user.utilisateur_uid,
                        utilisateur_id: data.user.utilisateur_id,
                        societe_id: data.user.societe_id,
                        utilisateur_nom: data.user.utilisateur_nom,
                        utilisateur_prenom: data.user.utilisateur_prenom,
                        utilisateur_login: data.user.utilisateur_login,
                        utilisateur_pass: passwordHash
                    }).then (function(){

                        getDbData(newDb, data.token).then(() => {
                            setLoading({show:false, icon:'', message:''});
                            setSessionContext({
                                isConnected: true,
                                token: data.token,
                                sessionData : {
                                    user: data.user
                                }
                            });
                            // sessionStorage['token'] = data.token;
                            localStorage['token'] = data.token;
                            const db = new Dexie('Levrard');
                            //window.location.reload();
                            //history.push("/")
                            document.location.href='/'
                        });
                    }).catch(function(error) {
                        alert ("Ooops: " + error);
                        setSessionContext({});
                    });
                }
                execute();

            } else {
                setSessionContext({});
                console.log(response)
                alert("Mauvais login ou mot de passe")
                setLoading({show:false, message:'Connexion en cours'});
                setSessionContext({});
                //history.push('/')
                document.location.href='/'
            }
        }).catch(function(error) {
            setSessionContext({});
            if (!error.response) {
                // network error
                alert("Problème réseau : vous n'avez pas de connexion internet")
                setLoading({show:false, icon:'', message:''});
            } else {
                // http status code
                const code = error.response.status
                // response data
                const response = error.response.data
                alert(code + ':' + response)
                setLoading({show:false, icon:'', message:''});
            }
        });
    }

    const getDbData = async (newDb, token) => {
        //let session = JSON.parse(localStorage['session']);
        let tables = [
            'utilisateur',
            'technicien',
            'article',
            'article_categorie',
            'article_compose',
            'article_famille',
            'banque',
            'bsdd_emetteur',
            'civilite',
            'conditionnement',
            'contact',
            'contact_coord',
            'coord_type',
            'cuve_fioul',
            'dechet',
            'dechet_consistance',
            'document',
            'document_type',
            'duree',
            'heure_travail',
            'heure_travail_imprevue',
            'intervention',
            'intervention_contacts',
            'intervention_dechet',
            'intervention_detail',
            'intervention_etat',
            'intervention_local',
            'intervention_materiels',
            'intervention_signalement',
            'intervention_signature',
            'intervention_commentaire',
            'intervention_type',
            'materiel',
            'materiel_categorie',
            'parc',
            'parc_categorie',
            'parc_entretien',
            'parc_type',
            'tiers',
            'tiers_adresse',
            'tiers_cap',
            'tiers_categorie',
            'tiers_tarif',
            'tiers_type',
            'tva',
            'tva_taux',
            'unite',
            'reglement',
            'contrat',
            'contrat_detail',
            'intervention_logement',
            'tiers_logement',
            'intervention_logement_type',
            'intervention_traitement',
            'fiche_explicative',
            'feedback_type',
            'feedback',
            'questionnaire',
            'questionnaire_rubrique',
            'question',
            'reponse',
            'questionnaire_resultat',
            'intervention_autre_type',
            'absence_type',
            'absence',
            'adresse_categorie',
            'intervention_logement_vente'
        ];

        try {
            for(let i = 0; i < tables.length; i++) {
                //console.log("Table : " + tables[i])
                newDb[tables[i]].clear();
                var response = await axios.post('/webservice/' + appVersion + '/getData.php?token=' + token + '&todo=' + tables[i]).then(async function(response){
                    var {data} = response;
                    for (var row in data) {
                        if (tables[i] == 'utilisateur'){
                            let salt = bcrypt.genSaltSync(10);
                            let passwordHash = bcrypt.hashSync(data[row]['utilisateur_pass'], salt);
                            data[row]['utilisateur_pass'] = passwordHash;
                        }
                        await newDb[tables[i]].put(data[row]);
                    }
                    setLoading({show:true, icon:'pacman', message:'Téléchargement des données (' + i + '/' + tables.length + ')'});
                }).catch(function(error) {
                    if (!error.response) {
                        // network error
                        alert("Problème réseau : vous n'avez pas de connexion internet")
                        setLoading({show:false, icon:'', message:''});
                    } else {
                        // http status code
                        const code = error.response.status
                        // response data
                        const response = error.response.data
                        alert(code + ':' + response)
                        setLoading({show:false, icon:'', message:''});
                    }
                });

            }
        } catch (error) {
            console.log(error);
            alert(error)
        }
    }

    async function changeSchema(db, schemaChanges) {

        db.close();
        const newDb = new Dexie(db.name);

        newDb.on('blocked', ()=>false); // Silence console warning of blocked event.

        // Workaround: If DB is empty from tables, it needs to be recreated
        if (db.tables.length === 0) {
            await db.delete();
            newDb.version(1).stores(schemaChanges);
            localStorage.setItem('verno', 1)
            return await newDb.open();
        }

        // Extract current schema in dexie format:
        const currentSchema = db.tables.reduce((result,{name, schema}) => {
            result[name] = [
                schema.primKey.src,
                ...schema.indexes.map(idx => idx.src)
            ].join(',');
            return result;
        }, {});
        // console.log("Version: " + db.verno);
        // console.log("Current Schema: ", currentSchema);

        var version = (localStorage.getItem('verno')*1 === 0 || localStorage.getItem('verno') === 'null')?1:localStorage.getItem('verno');

        // Tell Dexie about current schema:
        newDb.version(db.verno).stores(currentSchema);
        // Tell Dexie about next schema:
        newDb.version(db.verno + 1).stores(schemaChanges);
        localStorage.setItem('verno', newDb.verno);
        // Upgrade it:

        return await newDb.open();
    }

    const handleSubmit = (e) => {
        if (e) e.preventDefault();

        const form = e.currentTarget;
        if (form.checkValidity() === false) { //Formulaire no valide

            setValidated("false");
            let formErrorHelperTmp = {...formErrorHelper};
            let formErrorTmp = {...formError};
            for (var input in formValidation) {
                if (formValidation[input] == 'required'){
                    formErrorHelperTmp[input] = 'Champ obligatoire';
                    formErrorTmp[input] = true;
                } else {
                    formErrorHelperTmp[input] = '';
                    formErrorTmp[input] = false;
                }
            }
            setFormErrorHelper(formErrorHelperTmp);
            setError(formErrorTmp);
        } else {
            setValidated("true");
            db.utilisateur.toArray(function (utilisateur) {
                if (utilisateur.length === 0){ //Pas d'utilisateur en local alors je lance l'installation via une synchronisation
                    initDb(formData.login, formData.password);
                } else { //je vérifie si login et mot de passe sont valide
                    let salt = bcrypt.genSaltSync(10);
                    let passwordHash = bcrypt.hashSync(formData.password, salt);
                    if (ifNull(utilisateur[0].utilisateur_login,'').toLowerCase() === formData.login.toLowerCase() && bcrypt.compareSync(formData.password, utilisateur[0].utilisateur_pass)){
                        setSessionContext({
                            isConnected: true,
                            token: utilisateur[0].token,
                            sessionData : {
                                user: {
                                    utilisateur_id: utilisateur[0].utilisateur_id,
                                    utilisateur_nom: utilisateur[0].utilisateur_nom,
                                    utilisateur_prenom: utilisateur[0].utilisateur_prenom,
                                    utilisateur_login: utilisateur[0].utilisateur_login
                                }
                            }
                        });
                        localStorage['token'] = utilisateur[0].token;
                        history.push('/')
                    } else {
                        alert("Mauvais identifiant ou mot de passe")
                        setLoading({show:false, message:'Connexion en cours'});
                        setSessionContext({isConnected:false, token:''});
                        //history.push('/')
                    }
                }
            });
        }
    }

    return (
        <React.Fragment>
            <Grid item style={{'padding':'15px'}} className={"app-body-full"}>
                <form ref={formLogin} className={classes.root} noValidate validated={validated}  autoComplete="off"  onSubmit={(e) => handleSubmit(e)} style={{'marginTop':'10%', 'width':'100%'}}>
                    <Grid container justify="center" alignItems="center" spacing={3} direction="column">
                        <Grid item xs={12} sm={12} lg={12}>
                            <Grid container justify="center" spacing={3} direction="column"  style={{'width':'350px', backgroundColor:'white', padding:'20px'}}>
                                <Grid item align="center">
                                    <img src={require("../images/logo.jpg")} style={{height: '80px', textAlign: 'right'}} alt={"Levrard Assainissement"}></img>
                                </Grid>
                                <Grid item>
                                    <h2>Connexion</h2>
                                </Grid>
                                <Grid item>
                                    <TextField id="login" fullWidth autoFocus  label="Identifiant" required defaultValue={formData.login} variant="outlined" helperText={formErrorHelper.login} onChange={handleChangeLogin} error={formError.login} />
                                </Grid>
                                <Grid item>
                                    <TextField id="password" type="password" autoComplete="current-password" fullWidth label="Mot de passe" required defaultValue={formData.password} variant="outlined" helperText={formErrorHelper.password} onChange={handleChangePassword}  error={formError.password} />
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="primary" fullWidth type={"submit"}>
                                        Se Connecter
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <br/>
                                    <Grid container justify="center" spacing={3}>
                                        <Grid item xs={12} sm={12} lg={12} align={"center"}>
                                            <Button onClick={()=> {confirmDeleteDatabase()}}>Effacer la base de données</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </Grid>
                </form>
                <br/>
                <br/>
                <br/>

            </Grid>
            { Loading.show ? <Loader message={Loading.message}/> : null }
        </React.Fragment>
    );
}

export default Login;

